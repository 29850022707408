<template>
  <div class="container">
    <BaseH1 :text="'Znajdź się na mapie'" />

    <div class="wrapper-geo">
      <BaseMapAutocompleteInput
        v-model:address="offer.address.value"
        :coordinates-lat-lng="coordinatesLatLng"
        @update:coordinates-lat-lng="$emit('update:coordinatesLatLng', $event)"
      />
      <BaseMap
        v-if="coordinatesLatLng"
        :coordinates-lat-lng="coordinatesLatLng"
        @update:coordinates-lat-lng="$emit('update:coordinatesLatLng', $event)"
        :zoom="zoom"
      />
    </div>

    <TextHint
      :text="'Nie używamy dokładnej lokalizacji dla Twojej prywatności'"
    />
  </div>
</template>

<script>
import { computed, reactive } from "@vue/reactivity";
import { watch } from "@vue/runtime-core";

import BaseH1 from "@/components/UI/Base/BaseH1.vue";
import TextHint from "@/components/UI/TextHint.vue";
import BaseMap from "@/components/Map/BaseMap.vue";
import BaseMapAutocompleteInput from "@/components/Map/BaseMapAutocompleteInput.vue";

export default {
  components: {
    BaseH1,
    TextHint,
    BaseMap,
    BaseMapAutocompleteInput,
  },

  props: {
    address: String,
    coordinatesLatLng: Array,
    zoom: Number,
  },

  emits: ["update:address", "update:isValid", "update:coordinatesLatLng"],

  setup(props, { emit }) {
    const offer = reactive({
      address: {
        value: props.address,
        isValid: false,
      },
    });

    watch(
      () => offer.address.value,
      (newAddress) => emit("update:address", newAddress)
    );

    watch(
      () => props.address,
      (newAddress) => {
        offer.address.value = newAddress;
      }
    );

    const isValid = computed(() => {
      return !!props.coordinatesLatLng;
    });

    watch(isValid, (newIsValid) => emit("update:isValid", newIsValid), {
      immediate: true,
    });

    return {
      offer,
      isValid,
    };
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 40px;
}
.wrapper-geo {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}

@media (max-width: 1200px) {
}
</style>
