<template>
  <div class="content">
    <OfferNannyInputAddress
      v-model:address="offerNanny.details.location.address"
      @update:is-valid="isValid = $event"
      v-model:coordinates-lat-lng="coordsLatLng"
      :zoom="16"
    />

    <ButtonPrimaryNext @click="handleButtonNext()" :isDisabled="!isValid" />
  </div>
</template>

<script>
import { computed, ref } from "@vue/reactivity";
import { onMounted } from "vue";
import { useUserProfileStore } from "@/stores/userProfile";
import { useOfferNannyStore } from "@/stores/offerNanny";
import { useRouter } from "vue-router";

import { ROUTE_OFFER_NANNY_CREATE_AUPAIR_NAME } from "@/router/constants";

import OfferNannyInputAddress from "@/components/Offer/Nanny/OfferNannyInputAddress.vue";
import ButtonPrimaryNext from "@/components/UI/ButtonPrimaryNext.vue";
import useGoogleMapsGeocoder from "@/composables/useGoogleMapsGeocoder";
import useCoordsReversed from "@/composables/useCoordsReversed";
import useCommonDictionary from "@/composables/useCommonDictionary";

export default {
  components: {
    OfferNannyInputAddress,
    ButtonPrimaryNext,
  },

  setup() {
    // Stores
    const userProfile = useUserProfileStore();
    const offerNanny = useOfferNannyStore();

    // Hooks
    const router = useRouter();
    const { coordsLatLngToCityName } = useGoogleMapsGeocoder();
    const { coordsReversed: coordsLatLng } = useCoordsReversed({
      coordsRef: computed(() => offerNanny.details.location.point.coordinates),
      setCoords: (newCoords) => {
        offerNanny.details.location.point.coordinates = newCoords;
      },
    });

    // Vars
    const isValid = ref(null);
    // TODO: make default coordinates dynamic. Currently set coords of the Wroclaw city center
    const DEFAULT_COORDINATES = [17.0385376, 51.1078852]; // in LngLat !!! mongo style

    const setInitialLocationPoint = () => {
      if (offerNanny.details.location.point.coordinates.length > 0) {
        return;
      }

      let locationPoint = userProfile.zipLocationPoint;

      if (!locationPoint) {
        // Set default coordinates
        locationPoint = {
          type: "Point",
          coordinates: DEFAULT_COORDINATES,
        };
      }

      offerNanny.details.location.point = locationPoint;
    };

    const { DICTIONARY_COMMON } = useCommonDictionary();

    const getUserCity = async () => {
      console.log("GET_USER_CITY_NAME");
      let cityName = null;

      try {
        cityName = await coordsLatLngToCityName(coordsLatLng.value);
      } catch (error) {
        console.log(error, "ERROR: -> RETURN_DEFAULT_CITY_FROM_MARKET");

        // Set default city from cityMarket
        const userCityMarketSlug = offerNanny.details.location.cityMarket;
        cityName = DICTIONARY_COMMON[userCityMarketSlug];
      }

      return cityName;
    };

    const setUserCity = async (cityName) => {
      console.log(`SET_CITY_TO_APPLICATION_LOCATION: ${cityName}`);
      offerNanny.details.location.city = cityName;
    };

    const handleButtonNext = async () => {
      const userCity = await getUserCity();
      setUserCity(userCity);
      router.push({ name: ROUTE_OFFER_NANNY_CREATE_AUPAIR_NAME });
    };

    onMounted(() => {
      setInitialLocationPoint();
    });

    return {
      offerNanny,
      isValid,
      handleButtonNext,
      coordsLatLng,
    };
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  row-gap: 40px;
}
.wrapper-geo {
  width: 100%;

  display: flex;
  flex-flow: column;
  align-items: center;
  row-gap: 25px;
}

@media (max-width: 1200px) {
}
</style>
